import React, { useState, forwardRef } from "react";

const Contact = forwardRef(({ pub_url }, ref) => {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [zip, setZip] = useState('');
    const [message, setMessage] = useState('');
    const [formStatus, setFormStatus] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('number', number);
        formData.append('zip', zip);
        formData.append('message', message);

        try {
            const response = await fetch('https://formsubmit.co/empireroofing177@gmail.com', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setFormStatus('Thank you for contacting us!');
                setName('');
                setNumber('');
                setZip('');
                setMessage('');
            } else {
                setFormStatus('Something went wrong');
            }
        } catch (error) {
            setFormStatus('Something went wrong');
            console.log(error);
        }
    };

    return (
        <div
            className='contact'
            style={{ backgroundImage: `url(${pub_url}/images/contact.jpg)` }}
            ref={ref} // Forward the ref to this div
        >
            <div className='contact-container'>
                <h2>LOOKING TO GET A QUOTE?</h2>
                <div className='line'/>
                <form onSubmit={handleSubmit}>
                    <h4>Contact Form</h4>
                    <input
                        required
                        type='text'
                        placeholder='Enter Your Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <label>Your Name</label>
                    <input
                        required
                        type='text'
                        placeholder='Enter Your Number'
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                    />
                    <label>Your Phone Number</label>
                    <input
                        type='text'
                        placeholder='Enter Your Zip Code'
                        value={zip}
                        onChange={(e) => setZip(e.target.value)}
                    />
                    <label>Your Zip Code</label>
                    <textarea
                        placeholder="How Can We Help?"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <label>Your Message</label>
                    <button type='submit'>SUBMIT</button>
                </form>
                {formStatus && <p>{formStatus}</p>}
                <div className='line'/>
                <p>GET IN TOUCH WITH US DIRECTLY</p>
                <a href='mailto:empireroofing177@gmail.com'>empireroofing177@gmail.com</a><br/>
                <a href='tel:3466260021'>(346) 626 0021</a>
            </div>
        </div>
    );
});

export default Contact;
