const About = ({ pub_url }) => {
    return(
        <div className='about'>
            <h2>WHY CHOOSE US?</h2>
            <div>
                <img src={pub_url+'/images/about1.png'} alt='about-icon'/>
                <h1>Experienced
                Professionals</h1>
                <p>Our team has years of experience in the roofing industry</p>
            </div>
            <div>
                <img src={pub_url+'/images/about2.png'} alt='about-icon'/>
                <h1>High-Quality
                Materials</h1>
                <p>We use only the best materials to ensure durability and longevity</p>
            </div>
            <div>
                <img src={pub_url+'/images/about3.png'} alt='about-icon'/>
                <h1>Affordable
                Pricing</h1>
                <p>Competitive rates without compromising on quality</p>
            </div>
            <div>
                <img src={pub_url+'/images/about4.png'} alt='about-icon'/>
                <h1>Customer
                Satisfaction</h1>
                <p>We prioritize our customers’ satisfaction and guarantee excellent service</p>
            </div>
        </div>
    );
}

export default About;