import React, { useEffect, useRef, forwardRef } from 'react';

const Service = forwardRef(({ pub_url }, ref) => {
    const serviceRef = useRef(null);
    const titleRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const serviceEl = serviceRef.current;
            const titleEl = titleRef.current;
            if (!serviceEl || !titleEl) return;

            const serviceRect = serviceEl.getBoundingClientRect();
            const titleHeight = titleEl.offsetHeight;

            if (serviceRect.top <= 20 && serviceRect.bottom >= titleHeight) {
                titleEl.style.position = 'fixed';
                titleEl.style.top = '17px';
                titleEl.style.right = '-17px';
            } else if (serviceRect.bottom < titleHeight + 20) {
                titleEl.style.position = 'absolute';
                titleEl.style.top = 'auto';
                titleEl.style.bottom = '0';
                titleEl.style.right = '0';
            } else {
                titleEl.style.position = 'absolute';
                titleEl.style.top = '0';
                titleEl.style.right = '0';
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='services' ref={(node) => {
            serviceRef.current = node;
            if (typeof ref === 'function') ref(node);
            else if (ref) ref.current = node;
        }}>
            <h2 ref={titleRef}>SER<br/>VIC<br/>ES</h2>
            <div className='service-tile'>
                <img src={pub_url+'/images/roofinstallation.jpg'} alt="Roof Installations" />
                <div>
                    <h1>ROOF INSTALLATIONS</h1>
                    <p>Empire Roofing offers expert Roof Installation services to ensure a durable and visually appealing roof for your property. From material selection to installation, our skilled team ensures a high-quality finish that meets industry standards. We prioritize precision and reliability, delivering a roof that enhances both protection and value for your home or business.</p>
                </div>
            </div>
            <div className='service-tile'>
                <img src={pub_url+'/images/roofrepair.jpg'} alt="Roof Repairs" />
                <div>
                    <h1>ROOF REPAIRS</h1>
                    <p>Empire Roofing provides efficient Roof Repair services to quickly address and resolve issues with your roof. Our experienced team expertly identifies and fixes leaks, replaces damaged shingles, and repairs flashing to restore your roof’s integrity. We use high-quality materials and ensure a reliable repair, enhancing the protection and longevity of your property.</p>
                </div>
            </div>
            <div className='service-tile'>
                <img src={pub_url+'/images/roofinspection.jpg'} alt="Roof Inspections" />
                <div>
                    <h1>ROOF INSPECTIONS</h1>
                    <p>Empire Roofing offers thorough Roof Inspection services to assess the condition of your roof and identify any potential issues. Our experienced inspectors evaluate all components, including shingles, flashing, and gutters, to detect damage or wear. We provide a detailed report and recommend necessary repairs or maintenance, ensuring your roof remains in optimal condition and protecting your property effectively.</p>
                </div>
            </div>
            <div className='service-tile'>
                <img src={pub_url+'/images/roofmaintenance.png'} alt="Roof Maintenance" />
                <div>
                    <h1>ROOF MAINTENANCE</h1>
                    <p>Empire Roofing provides comprehensive Roof Maintenance services to keep your roof in top condition and extend its lifespan. Our team performs regular inspections, cleans gutters, and addresses minor repairs to prevent larger issues. By ensuring all components are functioning properly, we help you avoid costly repairs and maintain the protection and integrity of your property.</p>
                </div>
            </div>
            <div className='service-tile'>
                <img src={pub_url+'/images/emergency.jpg'} alt="Emergency Roofing Services" />
                <div>
                    <h1>EMERGENCY ROOFING SERVICES</h1>
                    <p>Empire Roofing offers prompt Emergency Roofing Services to address urgent issues and prevent further damage. Whether it’s due to severe weather or unexpected damage, our team quickly responds to assess and repair your roof, ensuring immediate protection and peace of mind. We prioritize rapid and effective solutions to restore your roof’s integrity and safeguard your property.</p>
                </div>
            </div>
        </div>
    );
});

export default Service;
