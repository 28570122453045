import React, { useEffect } from 'react';

const Hero = ({ pub_url, scrollToSection }) => {
    const scroll = (val) => {
        scrollToSection(val);
    };

    useEffect(() => {
        const el = document.querySelector('#dynamic-cursor');

        if (el) {
            const handleMouseMove = (e) => {
                const windowWidth = window.innerWidth;
                el.style.backgroundPositionX = `${(e.clientX / windowWidth - 0.3) * 20 + 30}%`;
            };

            el.addEventListener('mousemove', handleMouseMove);

            // Clean up the event listener on component unmount
            return () => {
                el.removeEventListener('mousemove', handleMouseMove);
            };
        }
    }, []); // Empty dependency array ensures this runs once when the component mounts

    return (
        <div className='page-section hero' id='dynamic-cursor' style={{ backgroundImage: `url(${pub_url}/images/hero.png)` }}>
            <div className='title-nav'>
                <img src={`${pub_url}/images/logo.png`} alt='logo' />
                <ul>
                    <li onClick={() => scroll('Services')}>SERVICES</li>
                    <li onClick={() => scroll('Gallery')}>GALLERY</li>
                    <li onClick={() => scroll('Contact')}>CONTACT</li>
                </ul>
            </div>
            <div className='hero-cta'>
                <h2>LOOKING FOR RELIABLE AND PROFESSIONAL ROOFING SERVICES?</h2>
                <p>Look no further!  At EMPIRE ROOFING, we offer top-quality roofing services tailored to meet your needs.  Whether you need a new roof installation, repairs, or maintenance, our experienced team is here to help.</p>
                <button onClick={() => scroll('Contact')}>GET A QUOTE</button>
            </div>
        </div>
    );
};

export default Hero;
