import Marquee from "react-fast-marquee";
import About from "./About";
import Hero from "./Hero";
import Service from "./Services";
import Contact from "./Contact";
import { useRef } from "react";

const Main = () => {
    const pub_url = process.env.PUBLIC_URL;
    const serviceRef = useRef(null);
    const galleryRef = useRef(null);
    const contactRef = useRef(null);

    const scrollToSection = (section) => {
        console.log(section)
        switch(section){
            case 'Services':
                serviceRef.current?.scrollIntoView({behavior: 'smooth'});
                break;
            case 'Gallery':
                galleryRef.current?.scrollIntoView({behavior: 'smooth'});
                break;
            case 'Contact':
                contactRef.current?.scrollIntoView({behavior: 'smooth'});
                break;
        }
    }

    return(
        <div className='page-wrapper'>
            <Hero pub_url={pub_url} scrollToSection={scrollToSection}/>
            <About pub_url={pub_url}/>
            <Service pub_url={pub_url} ref={serviceRef}/>
            <Marquee speed={10} pauseOnHover ref={galleryRef}>
                <img src={pub_url+'/images/img1.jpg'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img2.jpg'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img3.jpg'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img4.jpg'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img5.jpg'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img14.png'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img15.png'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img16.png'} className='gallery-tile' alt='gallery-photo'/>
            </Marquee>
            <Marquee speed={10} direction="right" pauseOnHover>
                <img src={pub_url+'/images/img6.jpg'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img7.jpg'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img8.jpg'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img9.jpg'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img10.png'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img11.png'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img12.png'} className='gallery-tile' alt='gallery-photo'/>
                <img src={pub_url+'/images/img13.png'} className='gallery-tile' alt='gallery-photo'/>
            </Marquee>
            <Contact pub_url={pub_url} ref={contactRef}/>
        </div>
    );
}

export default Main;